
import { RetrivePnr } from "./RetrivePnr";

// -------RetrivePnr Call----------------
export const retrivePnrDetails = async(pnr) =>{
   try{
      const result = await RetrivePnr(pnr);
      return result;

   }catch(error){
      console.error("Error While Fetching RetrivePnr",error);
   }
}