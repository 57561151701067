import React,{useState, useMemo} from 'react'
import HelpLineCard from '../Commom/HelpLineCard';
import BlogsSearchEngine from '../BlogsContent/BlogsSearchEngine';
import { TextField, Box, Typography, InputAdornment,Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useFormik } from 'formik';
import { validationSchema } from '../Commom/FormikSchemas';
import { VisaLeadCreation } from '../../API/BackendAPI/ArmanSirAPIs/VisaLeadCreation';
import { useParams } from "react-router-dom";
import { Select } from "antd";
import cities from '../../../src/Constant/airport';
import Flag from 'react-world-flags';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const { Option } = Select;
const VisaCategory = () => {

   const {visaType} = useParams();

   let departureSingle = null;
   let arrivalSingle = null;
  //  const municipalities = cities.map((city) => city.municipality);
  //  const iata_codes = cities.map((city) => city.iata_code);
  //  const airport_name = cities.map((city) => city.Name);
  //  const countery_name = cities.map((city) => city.iso_country);

  //  console.log("municipalities",municipalities);
  //  console.log("iata_codes",iata_codes);
  //  console.log("airport_name",airport_name);

  const {municipalities, iata_codes, airport_name, countery_name } = useMemo(() => {
    return {
      iata_codes: cities.map((city) => city.iata_code),
      airport_name: cities.map((city) => city.Name),
      countery_name: cities.map((city) => city.iso_country),
      municipalities: cities.map((city) => city.municipality),
    };
  }, [cities]);


   const concatenatedAirportsValues = [];

   const [departure, setDeparture] = useState(departureSingle);
   const [arrival, setArrival] = useState(arrivalSingle);
  //  const [searchResults, setSearchResults] = useState([]);
    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit:async (values , { setSubmitting, resetForm}) => {
     try{
      const updatedValues  = {...values, departure, arrival};
      console.log("passingValue",updatedValues);
      const response = await VisaLeadCreation(values, visaType);
      
      if (!response) {
        throw new Error("Failed to submit form");
      }
      alert("Form submitted successfully!",response);
      resetForm(); 
     }catch(error){
         console.error('Error:', error);
         throw error;
     }finally {
      setSubmitting(false);
    }
    },
  });

  // ------------------------------
//   const handleDepartureSelect = (selectedValue) => {
//     if (!selectedValue) {
//       setDeparture(null);
//     } else {
//       const extractedDeparture = selectedValue.split(",")[0].trim();
//       if (extractedDeparture === arrival) {
//         setDeparture(null);
//       } else {
//         setDeparture(extractedDeparture);
//       }
//     }
//   };
//   const handleDepartureSearch = (value) => {
//     setDeparture(value);
  
//     if (value.length >= 3) {
//         const filteredCities = concatenatedAirportsValues.filter((city) => {
//             const concatenatedValue = city.airport;
//             const iataCode = concatenatedValue.match(/\((.*?)\)/)?.[1]?.trim();
//             const cityName = concatenatedValue.replace(/\(.*?\)/, '').trim();
//             const airportName = concatenatedValue.split(',')[1]?.trim();
  
//             return (
//                 (iataCode && iataCode.toLowerCase().includes(value.toLowerCase())) ||
//                 cityName.toLowerCase().startsWith(value.toLowerCase()) ||
//                 (airportName && airportName.toLowerCase().startsWith(value.toLowerCase()))
//             );
//         });
  
//         // Separate into groups
//         const iataMatches = filteredCities.filter((city) => {
//             const iataCode = city.airport.match(/\((.*?)\)/)?.[1]?.trim();
//             return iataCode && iataCode.toLowerCase().includes(value.toLowerCase());
//         });
  
//         const otherMatches = filteredCities.filter((city) => {
//             const iataCode = city.airport.match(/\((.*?)\)/)?.[1]?.trim();
//             return !(iataCode && iataCode.toLowerCase().includes(value.toLowerCase()));
//         });
  
//         // Combine with IATA matches on top
//         const sortedCities = [...iataMatches, ...otherMatches];
  
//         setSearchResults(sortedCities);
//     } else {
//         setSearchResults([]);
//     }
//   };
//   for (let i = 0; i < municipalities.length; i++) {
//     const municipality = municipalities[i];
//     const iataCode = iata_codes[i];
//     const airportName = airport_name[i];
//     const counteryName = countery_name[i];
//     const flagUrl = counteryName;
//     const concatenatedValue = `${municipality} (${iataCode}), ${airportName}, ${counteryName}`;
//     const airportWithFlag = {
//       airport: concatenatedValue,
//       flag: flagUrl
//     };
//     concatenatedAirportsValues.push(airportWithFlag);
//   }

//   const handleArrivalSelect = (selectedValue) => {
//     if (!selectedValue) {
//       setArrival(null);
//     } else {
//       const extractedArrival = selectedValue.split(",")[0].trim();
//       if (extractedArrival === departure) {
//         setDeparture(null);
//       }
//       setArrival(extractedArrival);
//     }
//   };
//   const handleArrivalSearch = (value) => {
//     if (value.length >= 3) {
//         const filteredCities = concatenatedAirportsValues.filter((city) => {
//             const concatenatedValue = city.airport;
//             const iataCode = concatenatedValue.match(/\((.*?)\)/)?.[1]?.trim();
//             const cityName = concatenatedValue.replace(/\(.*?\)/, '').trim();
//             const airportName = concatenatedValue.split(',')[1]?.trim();

//             return (
//                 (iataCode && iataCode.toLowerCase().includes(value.toLowerCase())) ||
//                 cityName.toLowerCase().startsWith(value.toLowerCase()) ||
//                 (airportName && airportName.toLowerCase().startsWith(value.toLowerCase()))
//             );
//         });

//         // Separate into groups
//         const iataMatches = filteredCities.filter((city) => {
//             const iataCode = city.airport.match(/\((.*?)\)/)?.[1]?.trim();
//             return iataCode && iataCode.toLowerCase().includes(value.toLowerCase());
//         });

//         const otherMatches = filteredCities.filter((city) => {
//             const iataCode = city.airport.match(/\((.*?)\)/)?.[1]?.trim();
//             return !(iataCode && iataCode.toLowerCase().includes(value.toLowerCase()));
//         });

//         // Combine with IATA matches on top
//         const sortedCities = [...iataMatches, ...otherMatches];

//         setSearchResults(sortedCities);
//     } else {
//         setSearchResults([]);
//     }
// };

  // ---------------------------------
  return (
    <div className='container bg-white'>
        <div className='row pt-2'>
      <div className='col-md-8 col-sm-8'
    //    style={{ background: `url(${images.planelogo}) no-repeat bottom right ` }}
       >
      <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }} >
            <Typography variant="h6" gutterBottom className='edit_visaTypo'>
                Visa Inquiry Form
            </Typography>

            <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <TextField
                fullWidth
                label="Your Name"
                required
                margin="dense"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <PersonIcon />
                    </InputAdornment>
                    ),
                }}
                />

                <TextField
                fullWidth
                label="Phone Number"
                required
                margin="dense"
                name="phone"
                placeholder="Click to type"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <PhoneIcon />
                    </InputAdornment>
                    ),
                }}
                />

                {/* City Field */}
                <TextField
                fullWidth
                label="Visa Country"
                required
                margin="dense"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <LocationOnIcon />
                    </InputAdornment>
                    ),
                }}
                />

                {/* ----------------------------------- */}
                {/* <div className="mt-2  inputs_parents">
                  <Select
                    value={departure}
                    placeholder="Leaving From"
                    onChange={handleDepartureSelect}
                    onSearch={handleDepartureSearch}
                    className="inputDeprturFlightMob_2"
                    showSearch
                    showArrow
                    allowClear
                  >
                  {searchResults.map((city) => (
                      <Option key={city.airport} value={city.airport}>
                        <Flag code={city.flag} className="flagDesignn" />
                        {city.airport}
                      </Option>
                    ))}
                 </Select>
                  <Select
                    value={arrival}
                    placeholder="Going To"
                    onChange={handleArrivalSelect}
                    onSearch={handleArrivalSearch}
                    className="inputArrivalFlightMob_2"
                    showSearch
                    showArrow
                    allowClear
                  >
                    {searchResults.map((city) => (
                      <Option key={city.airport} value={city.airport}>
                        <Flag code={city.flag} className="flagDesignn" />
                        {city.airport}
                      </Option>
                    ))}
                  </Select>
                </div> */}
                {/* ----------------------------------------------- */}

                {/* Email Field */}
                <TextField
                fullWidth
                label="E-mail"
                margin="dense"
                placeholder="Optional"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <EmailIcon />
                    </InputAdornment>
                    ),
                }}
                />

                {/* Comments Field */}
                <TextField
                fullWidth
                label="Comments"
                margin="dense"
                name="comments"
                multiline
                rows={3}
                value={values.comments}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.comments && Boolean(errors.comments)}
                helperText={touched.comments && errors.comments}
                />

                {/* Submit Button */}
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
            </form>
            </Box>
         </div>
         <div className='col-md-4 col-sm-4'>
            <div className='my-2 mx-1'>
            <BlogsSearchEngine/>
            <HelpLineCard/>
            </div>
         </div>
        </div>
    </div>
  )
}

export default React.memo(VisaCategory);