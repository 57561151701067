export const VisaLeadCreation = async (data ,visaType) => {
    const baseUrl = `https://fmcrm.azurewebsites.net/Handlers/FMConnectApis.ashx`;

    const params = new URLSearchParams({
        type: 8,
        Username:"WEBONLN",
        UserTypeId: 3,
        to:"MED",
        from:"LHE",
        SectorFrom: "LHE",
        SectorTo: "MED",
        leadID: "0",
        takenOverType: 1,
        name: data.name || "Kashif Hussain",
        phone: data.phone || "03238864614",
        location: "LAHORE",
        email: data.email || "nomail@gmail.com",
        LeadText: `${visaType}-${data.comments}` || "none",
        custPriority: "Priority",
        isWebUser: 0,
        comeFrom: "By Google",
        BranchRef: "",
        adult:  1,
        child: 0,
        infant:0,
        service_type: "visa",
        airline: "Any",
        classtype:  "Economy",
        callType: 1,
        tripType: 2,
        depDate: "20-02-2024",
        retDate: "20-03-2024",
        secphone: data.phone || "03072477709"
    });

    const url = `${baseUrl}?${params.toString()}`;
    console.log("VisaLeadCreation URL:", url);
    try{
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        });
  
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        const result = await response.json();
        console.log('VisaLead_Success:', result);
        return result;
    }catch(error){
        console.error('Error:', error);
        throw error;
    }   
}