      // Define validation schema
      import * as Yup from 'yup';
      
  export const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .min(10, "Must be at least 10 digits")
      .required("Phone number is required"),
    email: Yup.string().email("Invalid email format"),
    comments: Yup.string(),
  });