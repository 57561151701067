export const RetrivePnr = async(pnr) =>{

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  "PNR_Retrieve": {
    "retrievalFacts": {
      "retrieve": {
        "type": "2"
      },
      "reservationOrProfileIdentifier": {
        "reservation": {
          "controlNumber": `${pnr}`
        }
      }
    }
  },
  "session": {
    "TransactionStatusCode": "Start",
    "SessionId": "",
    "SequenceNumber": "",
    "SecurityToken": ""
  }
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

try{
    const resp = await fetch("https://api.faremakers.com/api/amadeus-live/retrive_pnr", requestOptions);
    const result = await resp.json();
    const pnrRetriveBody =result?.data?.['soapenv:Envelope']?.['soapenv:Body'];
    return pnrRetriveBody
}catch(error){
    console.error("Error While Fetching RetrivePNR",error);
    return null;
}

}