import * as images from '../../Constant/images';

export const visaSections = [
    {
        "id": 1,
        "title": "UAE Visit Visa",
        backgroundImage: require('../../assets/visas/dubai.png'),
        url: '/uae-visit',
    },
    {
        "id": 2,
        "title": "Umrah Visa",
        backgroundImage: require('../../assets/visas/umrah_visa.png'),
        url:'/umerah',
    },
    {
        "id": 3,
        "title": "Azerbaijan BAKU Visa",
        backgroundImage: require('../../assets/visas/azerbhaijan.png'),
        url: '/azerbaijan-baku',
    },
    {
        "id": 4,
        "title": "Malaysia E Visa",
        backgroundImage: require('../../assets/visas/malaysia.png'),
        url: '/malaysia-evisa',
    },
    {
        "id": 5,
        "title": "Cambodia E Visa",
        backgroundImage: require('../../assets/visas/combodia.png'),
        url: '/cambodia-evisa',
    },
    {
        "id": 6,
        "title": "Singapore E Visa",
        backgroundImage: require('../../assets/visas/singapore.png'),
        url: '/singapore-evisa',
    },
    {
        "id": 7,
        "title": "Sri Lanka E Visa",
        backgroundImage: require('../../assets/visas/sirilanka.png'),
        url: '/sri-lanka-evisa',
    },
    {
        "id": 8,
        "title": "Turkey E Visa",
        backgroundImage: require('../../assets/visas/turkey.png'),
        url: '/turkey-evisa',
    },
    {
        "id": 9,
        "title": "Indonesia E Visa",
        backgroundImage: require('../../assets/visas/indonasia.png'),
        url: '/indonesia-evisa',
    },
    {
        "id": 10,
        "title": "Thailand E Visa",
        backgroundImage: require('../../assets/visas/thailand.png'),
        url: '/thailand-evisa',
    }

]