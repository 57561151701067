import React from 'react'
import * as images from '../../Constant/images';
import { visaSections } from './visaSectionsJSON';
import {useNavigate} from 'react-router-dom';
const VisasDetail = () => {
    const navigate = useNavigate();
    const handleNavigation = (url) => () => {
        const visaType = url
       navigate(`/visa${visaType}`);
    }
  return (
    <div className="container bg-white">
      <div className="visas_box">
        <h3 className="contact_us_heading">Get Your Visa, With Faremakers!</h3>
        <div className="row g-2 mt-2">
          {
            visaSections.map((items,index)=>{
                return(
                    <div className=" col-md-4 col-sm-6" key={index}>
                        <div className='visas_tabs'   style={{ backgroundImage: `url(${items.backgroundImage}) ` }} onClick={handleNavigation(items.url)}>
                            <h3 className='visa_title  my-2'>{items.title}</h3>
                        </div>
                    </div>
                )
            })
          }
       </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default React.memo(VisasDetail);