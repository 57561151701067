import React, { useEffect,useState ,Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { retrivePnrDetails } from '../../API/AmadeousAPI';
import Loader from '../../Loader/Loader';
import * as image from '../../Constant/images';
import FlightIcon from '@mui/icons-material/Flight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
const RetrivePNRDetails = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const pnr = queryParams.get("pnr");
  const navigate = useNavigate()

  const [isLoading ,setLoading] = useState(false);
  const [isMobile , setIsMobile] = useState(window.innerWidth < 667);
  const [isSmallMobile , setSmallMobile] = useState(window.innerWidth < 480);
  const [isBreakFlex ,setBreakFlex] = useState(window.innerWidth < 767);

  const [pnrData ,setPnrData] = useState([]);

  // get pnrNum from Retrive pnr api
  const pnrNum = pnrData?.PNR_Reply?.pnrHeader?.reservationInfo?.reservation;

  // getting Passangers Info
  const passangerDetails = pnrData?.PNR_Reply?.travellerInfo?.map((items) => {
    return Array.isArray(items.passengerData) ? items.passengerData : [items.passengerData];
  }) || [];

  console.log("passangerDetails",passangerDetails);

  // get itineraryInfo 

  const itineraryInfo = pnrData?.PNR_Reply?.originDestinationDetails?.itineraryInfo;
  console.log("itineraryInfo",itineraryInfo);



  const getDetails = async() =>{
    try{
      setLoading(true);
      const resp = await retrivePnrDetails(pnr);
      console.log("resp",resp);
      setPnrData(resp);
      setLoading(false);
    }catch(error){
      console.error("Error While Fetching PNR Retrive Data")
    }finally{
      setLoading(false);

    }
  }

  useEffect(()=>{
    getDetails();
  },[]);

  useEffect(()=>{
      const handleResize = ()=>{
          setIsMobile(window.innerWidth < 667);
          setBreakFlex(window.innerWidth < 767)
      };
      window.addEventListener('resize', handleResize);
      return()=>{
          window.removeEventListener('resize', handleResize);
      }
  },[]);
  useEffect(()=>{
      const handleResize = ()=>{
          setSmallMobile(window.innerWidth < 480);
      };
      window.addEventListener('resize', handleResize);
      return()=>{
          window.removeEventListener('resize', handleResize);
      }
  },[]);

  // ------------------

      const qrCodeValue = pnr;
      const qrCodeSize = 70;
      const encryptText = (text) => {
          const myKey = 'KashifHussainTheCreatorofThisFareMakersSite';
          const encrypted = CryptoJS.TripleDES.encrypt(text, myKey).toString();
          return encrypted;
      };
      const hashEncripted = encryptText(qrCodeValue);
const handleNavigate = () =>{
    navigate('/');
}

  return (
    <div className='container bg-white'>
    {
      isLoading ? (<Loader/>) :(
        <div className="container bg-white p-5" >
                     <Fragment>
                     <div className="ticket_display" id="pdf-content">
                    {
                        isMobile ? (
                            <Fragment>
                            <div className="d-flex justify-content-between ">
                                <div  className="hdrLogo ETicket_main" onClick ={handleNavigate} >
                                    <img
                                        src={image.default}
                                        className="imgView"
                                        alt="FM-LOGO"
                                    />
                                    <span id="logotext" className="colorBlue d-block" >
                                        Travel Channel Int'l (Pvt).Ltd
                                    </span>
                                </div>
                                <QRCode value={`https://fmcrm.azurewebsites.net/ViewItinerary.aspx?PNR=${hashEncripted}=`} size={qrCodeSize} className="QR_Code_tytpo" />
                         </div>
                         <h3 className="colorBlue text-center mt-2">E-Reservation</h3>
                            </Fragment>
                            ):(
                            <div className="d-flex justify-content-between">
                            {/* <div id="logobox" className="hdrLogo" onClick ={handleNavigate} >
                                <img
                                    src={image.default}
                                    className="imgView"
                                    alt="FM-LOGO"
                                />
                                <span id="logotext" className="colorBlue d-block" >
                                    Travel Channel Int'l (Pvt).Ltd
                                </span>
                            </div> */}
                            <h1 className="colorBlue">E-Reservation</h1>
                            <QRCode value={`https://fmcrm.azurewebsites.net/ViewItinerary.aspx?PNR=${hashEncripted}=`} size={qrCodeSize} />
                        </div>
                        )
                    }
                    {
                        isBreakFlex ? (
                            <div className="mt-5">
                                    <div className=" d-flex justify-content-center">
                                                            <div>
                                                                    <div>
                                                                        <div className='d-flex justify-content-start journeys_spacing segment_spacing ticket_depart_date'>
                                                                            <h5 className="iti_city_font">KHI</h5> 
                                                                            <span className="airport_spacing"><RedoOutlinedIcon /></span> 
                                                                            <h5 className="iti_city_font">DXB</h5>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                    </div> 
                                    <div className="d-flex justify-content-center">
                                            <div className="">
                                                <p className="ticket_depart_date">{`•12/12/2025`}</p>
                                                {/* <ArrowRightIcon className="align-self-center ticket_right_arrrow"/>  */}
                                                <p className="ticket_depart_date">{`•12/12/2025`}</p>
                                            </div>
                                  </div> 
                                </div>
                        ):(
                            <div className="d-flex justify-content-between mt-5">
                            <div className="d-flex justify-content-start">
                                       <div className="d-flex justify-content-start">
                                        <p className="ticket_depart_date">{`•12/12/2025`}</p>
                                        <ArrowRightIcon className="align-self-center ticket_right_arrrow"/> 
                                         <p className="ticket_depart_date">{`•12/12/2025`}</p>
                                       </div>
                            </div>
                            <div className="d-flex justify-content-end ">
                                <>
                                        <div >
                                            <div className='d-flex justify-content-start journeys_spacing ticket_depart_date'>
                                                <h5 className="iti_city_font">KHI</h5> 
                                                <span className="airport_spacing"><RedoOutlinedIcon /></span> 
                                                <h5 className="iti_city_font">DXB</h5>
                                            </div>
                                        </div>

                                {/* --------------------- */}
                                </>
                            </div>    
                        </div>
                        )
                    }
                     <div className="table-responsive ">
                            <table className="table table-bordered mt-3">
                                    <thead>
                                    <tr>
                                        <th>F-Name</th>
                                        <th>L-Name</th>
                                        <th>Passport#</th>
                                        <th>Type</th>
                                        {isSmallMobile ? <th>Receipt(s)</th> : <th>eTicket Receipt(s)</th>}
                                    </tr>
                                    </thead>
                                    {
                                      passangerDetails?.map((items ,index)=>(
                                    <tbody  key = {index} >
                                      {
                                        items?.map((itm ,idx)=>(
                                      <tr key = {idx}>
                                           <td>{itm?.travellerInformation.passenger?.firstName}</td>
                                           <td>{itm?.travellerInformation.traveller?.surname}</td>
                                           <td>YH6908781</td>
                                           <td>{itm?.travellerInformation.passenger?.type ? itm?.travellerInformation.passenger?.type : "ADT"}</td>
                                           <td>___</td>
                                      </tr>
                                        ))
                                      }
                                    </tbody>
                                      ))
                                    }
                                </table>
                     </div>
                           {
                            isSmallMobile ?(
                                <div className="d-flex justify-content-between mt-3">
                                  <h6><span>Booking-ID</span> {pnrNum?.controlNumber}</h6>
                                  <h6><span>Company-ID:</span>{pnrNum?.companyId}</h6>
                               </div>
                            ):(
                                <div className="d-flex justify-content-between mt-3">
                                    <h6  className='fw-bold'><span>Booking-Reference:</span>{pnrNum?.controlNumber}</h6>
                                    <h6 className='fw-bold'><span>Company-ID:</span>{pnrNum?.companyId}</h6>
                                </div>
                            )
                           }
                       {
                        itineraryInfo?.map((items ,index) =>(
                            <div key = {index}>
                          <div className="itineryDetailssty mt-4">
                                <div className="d-flex justify-content-start">
                                <div>
                                    <FlightIcon className="airplane-rotated-icon" />
                                </div>
                                <div>
                                    <h5>{`departure: ${items?.travelProduct?.product?.depDate}`}</h5>
                                    <h6 className="verify_prior">Please verify flight times prior to departure</h6>
                                </div>
                                </div>
                                <div className="row my-3 ">
                                    <div className="col-md-4 mb-3 ">
                                        <h4>{items?.travelProduct.companyDetail?.identification}</h4>
                                        <h5 className="mb-3">{items?.travelProduct.productDetails?.identification}</h5>
                                        <p><span className="span_verify_prior mt-2">Duration: </span>{items?.flightDetail?.productDetails?.duration || '--'}</p>
                                        <p><span className="span_verify_prior mt-2">ClassDesignator: </span>{items?.cabinDetails?.cabinDetails?.classDesignator || '--'}</p>
                                        <p><span className="span_verify_prior mt-2">Entertainment: </span>{items?.flightDetail?.facilities?.entertainement || '--'} </p>

                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <div className="row">
                                            <div className="d-flex justify-content-between text-center">
                                                <div>
                                                    <h4 className="font-weight-bolder">{items?.travelProduct.boardpointDetail?.cityCode} </h4>
                                                    <p className="airport_ticket_bok">Illama Iqbal  Airport</p>
                                                </div>
                                                <div><FlightIcon className="plane-mark-rotated-icon" /></div>
                                                <div>
                                                    <h4 className="font-weight-bolder">{items?.travelProduct.offpointDetail?.cityCode} </h4>
                                                    <p className="airport_ticket_bok">Dubai Airport</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 text-center">
                                            <div className="col">
                                                <p>Departing At: </p>
                                                <p className="ticket_book_det">{items?.travelProduct?.product?.depTime}</p>
                                                <p>Terminal: <span className="ticket_book_det">{items?.flightDetail?.departureInformation?.departTerminal || '--'}</span></p>
                                            </div>
                                            <div className="col">
                                                <p>Arrival  At: </p>
                                                <p className="ticket_book_det">{items?.travelProduct?.product?.arrTime}</p>
                                                <p>Terminal: <span className="ticket_book_det">{items?.flightDetail?.arrivalStationInfo?.terminal || '--'}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <p><span className="span_verify_prior mt-2">Flight No: </span>{`${items?.travelProduct.companyDetail?.identification}-${items?.travelProduct.productDetails?.identification}`}</p>
                                        <p><span className="span_verify_prior mt-2">Equipment: </span>{items?.flightDetail?.productDetails?.equipment || '--'}</p>
                                        <p><span className="span_verify_prior mt-2">Stop(s): </span>{items?.flightDetail?.productDetails?.numOfStops || '--'}</p>
                                        {/* <p><span className="span_verify_prior mt-2">Seat No: </span>0</p> */}
                                        <p><span className="span_verify_prior mt-2">TotalPassangers: </span>{passangerDetails?.length}</p>
                                        <p><span className="span_verify_prior mt-2">Baggage Allowence: </span>{`10 KG`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                       }
                        <div className="mt-3">
                            <p className="ticket_book_heading">TERMS AND CONDITIONS</p>
                            <p>No terms and conditions found refer to GDS for more details</p>
                            <p className="ticket_book_heading border-top mt-3 pt-3">Travel Channel Int. Pvt. Ltd.</p>
                            <p>3-UGF, Century Tower, Kalma Chowk Main Boulevard, Gulberg-III Lahore, Pakistan</p>
                            {
                                isMobile ? (
                                    <div className="mt-2">
                                        <p><span className="span_verify_prior">E-mail: </span>support@faremakers.com</p>
                                        <p><span className="span_verify_prior">UAN: </span> (+92-42) 03111-147-111</p>
                                    </div>
                                    ) :(
                                    <div className="d-flex justify-content-between mt-2">
                                        <p><span className="span_verify_prior">E-mail: </span>support@faremakers.com</p>
                                        <p><span className="span_verify_prior">UAN: </span> (+92-42) 03111-147-111</p>
                                    </div>
                                )
                            }
                            <p className="border-top mt-3 pt-3 E_Ticket_disc"><span className="span_verify_prior">www.Faremakers.com </span>Powered By Travel Channel International (Pvt.) Ltd. Pakistan. Which is Nationwide IATA accredited company, Founded in 2003 & successfully operating the business in Lahore, Karachi & Islamabad. Our Goal is Making Travel Simple & Easy through Giving Best Travel Services all over the Pakistan.</p>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                        <button className='btn btn-primary m-2 download_typography' onClick={() => downloadPDF()}>
                                Download as PDF
                        </button>
                    </div> */}
                     </Fragment>
                       
                    
                </div>
      )
    }
    </div>
  )
}

export default React.memo(RetrivePNRDetails);